<template>
  <section v-if="showTape && configHuincha.activo">
    <div
      class="h-full w-full relative py-3"
      :style="`background-color:${configHuincha.bgColor};`"
    >
      <div
        class="flex h-full items-center justify-center gap-2 sm:gap-0 px-6"
        :style="`color:${configHuincha.textColor};`"
        v-if="configHuincha.tipo === 'texto'"
      >
        <!-- Texto Desktop -->
        <span
          class="hidden text-center text-xs font-bold sm:text-lg md:block"
          v-html="configHuincha.textoDesktop"
        />
        <!-- Texto Mobile -->
        <span
          class="text-center text-sm font-bold sm:text-base md:hidden"
          v-html="configHuincha.textoMobile"
        />
        <CountdownHuincha
          v-if="
            configHuincha && configHuincha.fechasCountdown && mostrarCountdown
          "
          :rangosFecha="configHuincha.fechasCountdown"
          class="w-12 text-center text-xs font-bold sm:w-32 sm:text-base"
        />
      </div>
      <div
        class="flex h-full items-center justify-center"
        v-else-if="configHuincha.tipo === 'imagen'"
      >
        <!-- Imagen Desktop -->
        <div class="relative h-full">
          <img
            v-if="configHuincha.huinchaImg.desktop.length"
            width="1000px"
            height="40px"
            class="hidden h-full lg:block"
            :src="configHuincha.huinchaImg.desktop[0].url"
            alt="Huincha promocional, los mejores precios siempre en FREST"
          />

          <!-- Imagen Tablet -->
          <img
            v-if="configHuincha.huinchaImg.tablet.length"
            width="700px"
            height="40px"
            class="hidden h-full sm:max-lg:block"
            :src="configHuincha.huinchaImg.tablet[0].url"
            alt="Huincha promocional, los mejores precios siempre en FREST"
          />
          <!-- Imagen Mobile -->
          <img
            v-if="configHuincha.huinchaImg.mobile.length"
            width="360px"
            height="40px"
            class="block h-full sm:hidden"
            :src="configHuincha.huinchaImg.mobile[0].url"
            alt="Huincha promocional, los mejores precios siempre en FREST"
          />
          <CountdownHuincha
            v-if="
              configHuincha && configHuincha.fechasCountdown && mostrarCountdown
            "
            :rangosFecha="configHuincha.fechasCountdown"
            class="absolute -right-px bottom-3 text-xs font-bold sm:bottom-2 sm:right-20 sm:text-base lg:right-36"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
  // Imports
  import { computed, ref } from "@vue/runtime-core";
  import CountdownHuincha from "./CountdownHuincha.vue";
  import type { ConfigHuincha } from "@lib/interfaces";
  import dayjs from "dayjs";
  import isBetween from "dayjs/plugin/isBetween";
  dayjs.extend(isBetween);

  interface Props {
    configHuincha: ConfigHuincha;
  }

  // Variables
  const props = defineProps<Props>();
  const showTape = ref(true);

  // Computed variables
  const mostrarCountdown = computed<boolean>(() => {
    if (
      props.configHuincha === undefined ||
      props.configHuincha.fechasCountdown === null
    )
      return false;

    const hoy = dayjs();
    const desde = dayjs(props.configHuincha.fechasCountdown[0]);
    const hasta = dayjs(props.configHuincha.fechasCountdown[1]);
    return hoy.isBetween(desde, hasta, null, "[]");
  });
</script>
