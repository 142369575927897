<template>
  <div class="flex w-32 justify-center">
    <p>
      {{ timerString }}
    </p>
  </div>
</template>
<script setup lang="ts">
  // Imports
  import { ref, onMounted, onUnmounted } from "@vue/runtime-core";
  import dayjs from "dayjs";

  //Hooks
  onMounted(() => {
    interval.value = setInterval(() => {
      timerString.value = tiempoRestante();
    }, 1000);
  });

  onUnmounted(() => {
    clearInterval(interval.value);
  });

  // Variables
  const props = defineProps(["rangosFecha"]);
  const interval = ref<number | undefined>(undefined);
  const timerString = ref<string>("");
  // Computed variables
  const tiempoRestante = () => {
    const fechaActual = dayjs();
    const fechaEvento = dayjs(props.rangosFecha[1]);
    const diferencia = fechaEvento.diff(fechaActual);
    const segundos = 1000;
    const minutos = segundos * 60;
    const horas = minutos * 60;
    const dias = horas * 24;

    const diasRestantes = Math.floor(diferencia / dias);
    const horasRestantes = Math.floor((diferencia % dias) / horas);
    const minutosRestantes = Math.floor((diferencia % horas) / minutos);
    const segundosRestantes = Math.floor((diferencia % minutos) / segundos);

    const checkAppendCero = (valor: number) => {
      return valor < 10 ? `0${valor}` : valor;
    };

    const contadorDias = (valor: number) => {
      if (valor < 1 && valor >= 0) {
        return "";
      } else if (valor > 1) {
        return `${valor} días`;
      } else {
        return `${valor} día`;
      }
    };

    return `${contadorDias(diasRestantes)} ${checkAppendCero(
      horasRestantes,
    )}:${checkAppendCero(minutosRestantes)}:${checkAppendCero(
      segundosRestantes,
    )}`;
  };
</script>
